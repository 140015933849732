<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="show == true" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span style="cursor: pointer" @click="VolverPanel">Panel Administrativo</span> > <span @click="IrNodos" style="cursor: pointer"> Nodos</span> > Nuevo </strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>

                <section>
                    <b-row style="padding: 20px">
                        <div class="col-md-3">
                            <label for="Nombre de Usuario" style="padding-top:15px">Nombre de Nodo</label>
                            <input type="text" id="username"  v-model="nodo.nombre" class="form-control">
                        </div>
                        <div class="col-md-3">
                            <label for="Url de Nodo" style="padding-top:15px">Url de Nodo</label>
                            <input type="text" id="username"  v-model="nodo.url" class="form-control">
                        </div>

                        <div class="col-md-3">
                            <b-form-group
                                    label="Estado"
                                    label-for="Estado" style="padding-top:15px">
                                <div id="selector">
                                    <select v-model="nodo.estado" :class="'form-control'">
                                        <option v-for="(item) in estados" :value="item">
                                            {{item}}
                                        </option>
                                    </select>
                                    <br>
                                    <br>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <label for="Capacidad" style="padding-top:15px">Capacidad</label>
                            <input type="text" id="username"  v-model="nodo.capacidad" class="form-control">
                        </div>
                    </b-row>
                    <b-row style="padding: 20px">

                        <div class="col-md-3">
                            <label for="Ruta Videos SRC" style="padding-top:15px">Ruta Videos SRC</label>
                            <input type="text" id="username"  v-model="nodo.ruta_videos_src" class="form-control">
                        </div>
                        <div class="col-md-3">
                            <label for="Ruta Videos Originales" style="padding-top:15px">Ruta Videos Originales</label>
                            <input type="text" id="username"  v-model="nodo.ruta_videos_originales" class="form-control">
                        </div>
                        <div class="col-md-3">
                            <label for="Ruta Imagenes" style="padding-top:15px">Ruta Imagenes</label>
                            <input type="text" id="username"  v-model="nodo.ruta_imagenes" class="form-control">
                        </div>
                    </b-row>
                </section>

                <div style="padding: 10px"></div>
                <hr />

                <div style="padding: 10px"></div>
                <footer>
                    <mdb-btn style="float:left" color="primary" @click="CreateNodo" size="lg">Crear</mdb-btn>
                </footer>

                <div style="padding: 10px"></div>




            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';

    export default {
        name: 'Nuevo Nodo',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
        },
        data() {
            return {
                show: false,
                token : localStorage.getItem('token'),
                seleccionados: [],
                activo: false,
                estados : ['ON','OFF'],
                fields_usuarios: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'username', label: 'Nombre Usuario', sortable: true},
                    {key: 'email', label: 'Email', sortable: true},
                    {key: 'role', label: 'Rol', sortable: true},
                    {key: 'active', label: 'Activado', sortable: true},
                    {key: 'created_at', label: 'Fecha creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],
                nodo : {
                    nombre: '',
                    url: '',
                    estado: '',
                    capacidad: '',
                    ruta_videos_src: '',
                    ruta_videos_originales: '',
                    ruta_imagenes: '',
                },
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                selected_delegacion : null,
                selected_tipo : null,
                selected_orden: 'DESC',
                selected_nombre : '',
            }
        },
        methods: {

            CreateNodo()
            {
                ApiService.createNodo(this.nodo).then( r => {

                    console.log('actualizando usuario')
                    console.log(r);

                    if(r.status == 200)
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Nodo creado éxito',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        this.$router.push({path :'/admin/nodos'});

                    }
                    else
                    {
                        this.$swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Error',
                            text:'Ha habido algún error al crear',
                            showConfirmButton: true,
                        })

                    }
                }).catch(e => {
                    this.$swal.fire({
                        position: 'center',
                        type: 'error',
                        title: 'Error',
                        text:'Ha habido algún error al crear',
                        showConfirmButton: true,
                    })

                });

            },
            VolverPanel()
            {
                this.$router.push({path: '/admin'});
            },
            IrNodos()
            {
                this.$router.push({path: '/admin/nodos'});
            },

            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {


            },

        },
        mounted(){
            ApiService.getMe().then(r => {
                if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
                {
                    this.$swal.fire({
                        title: 'Acceso no autorizado',
                        text: 'No lo intentes, de aquí en adelante no tienes acceso',
                        type: 'error',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/'});
                }
                else {
                    this.show = true;
                }

            });

        },
        created(){


            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
